import { Experiment } from '@types'
import survey from '../survey'
// import { questions } from '@data/questions/glp1'

const exp2: Experiment = {
  ...survey,
  // questions,
  id: 'exp2',
  funnel: 'EXP2-NO-LEAD',
  default: false,
}

export default exp2
