import WeightGraph from './WeightGraph'
import ContinueButton from '../ContinueButton'
import Image from 'next/image'
import Lottie from 'lottie-react'
import checkmarkData from 'animations/checkmark.json'
import { Gender, Macros, Track } from '../../@types'
import { useExperiment } from 'redux/experiment'
import fireConfetti from '@utils/confetti'
import Router from 'next/router'
import { useEffect, useRef, useState } from 'react'
import { trackMixpanelEvent } from '@components/Scripts/MixpanelTracker'
import { useAppSelector } from 'redux/hooks'
import { CheckCircleIcon } from '@heroicons/react/24/solid'
import BeforeAndAfter from '@components/YourPlan/BeforeAndAfter'
import { GoodQuestionToAsk } from '@components/YourPlan/GoodQuestionToAsk'
import Footer from '@components/YourPlan/Footer'
import ReadyToSeeResults from '@components/YourPlan/ReadyToSeeResults'
import { trackEvent } from '@utils/tracking'
import Modal from '@components/Modal'
import { trackGAEvent } from '@components/Scripts/GoogleAnalytics'

type ResultsProps = {
  currentWeight: number
  targetWeight: number
  gender: Gender
  progressAmount: number
  track: Track
  height: number
  macros: Macros
}

function PriceComparisonTable({
  onClick: onClick,
}: {
  onClick?: () => void
  enabled: boolean
}) {
  return (
    <div className="max-w-5xl mx-auto my-8 p-5 md:p-8 md:py-10">
      <div className="mx-auto max-w-4xl text-center mb-10">
        <p className="text-3xl font-semibold font-serif tracking-tight text-gray-900">
          An affordable &amp; sustainable approach to weight loss
        </p>
      </div>

      <div className="flex flex-row justify-center items-start">
        {/* Left column for the names */}
        <div className="flex-1">
          <div className="h-16 flex items-center justify-center"></div>
          <ul className="divide-y divide-black/20">
            <li className="h-20 flex items-center text-sm font-medium">
              <div>Personalized treatment plan</div>
            </li>
            <li className="h-20 flex items-center text-sm font-medium">
              <div>Comprehensive clinician visit</div>
            </li>
            <li className="h-20 flex items-center text-sm font-medium">
              <div>Full metabolic assessment</div>
            </li>
            <li className="h-20 flex items-center text-sm font-medium">
              <div>
                Unlimited messaging with Care Team
                <span className="hidden md:block">
                  (including clinician and coach)
                </span>
              </div>
            </li>
            <li className="h-20 flex items-center text-sm font-medium">
              <div>Personalized dietary guide</div>
            </li>
            <li></li>
          </ul>
        </div>

        {/* Middle column for 'Measured' */}
        <div className="flex-1 bg-white rounded-3xl p-4 border-primary1 border-2">
          <div className="h-12 flex items-center justify-center">
            <div className="relative">
              <Image
                src={'/m-wordmark.svg'}
                alt="Measured Weight Loss"
                width={150}
                height={20}
              />
            </div>
          </div>
          <ul className="">
            <li className="flex justify-center items-center h-20">
              <span className="text-black w-10 h-10 rounded-full flex items-center justify-center bg-primary1">
                ✓
              </span>
            </li>
            <li className="flex justify-center items-center h-20">
              <span className="text-black w-10 h-10 rounded-full flex items-center justify-center bg-primary1">
                ✓
              </span>
            </li>
            <li className="flex justify-center items-center h-20">
              <span className="text-black w-10 h-10 rounded-full flex items-center justify-center bg-primary1">
                ✓
              </span>
            </li>
            <li className="flex justify-center items-center h-20">
              <span className="text-black w-10 h-10 rounded-full flex items-center justify-center bg-primary1">
                ✓
              </span>
            </li>
            <li className="flex justify-center items-center h-20">
              <span className="text-black w-10 h-10 rounded-full flex items-center justify-center bg-primary1">
                ✓
              </span>
            </li>
            <li className="flex justify-center items-center h-24">
              <div className="pt-4 text-center rounded">
                <div className="text-lg md:text-2xl flex flex-col md:flex-row items-center justify-center md:gap-3 md:mb-2">
                  <div className="discount-bubble rounded-full px-3 py-1 text-base bg-orange-600 font-semibold text-white tracking-wider hidden md:block">
                    50% off
                  </div>
                  <div className="sale-price font-semibold text-orange-600">
                    $39 today
                  </div>
                </div>
                <span className="text-sm">
                  $78/mo<span className="hidden md:inline">nth</span> going
                  forward
                </span>
              </div>
            </li>
          </ul>
        </div>

        {/* Right column for 'Other weight loss programs' */}
        <div className="flex-1 rounded-3xl py-4 hidden md:block">
          <div className="h-10 flex items-center justify-center">
            <div className="relative font-semibold">
              Other weight loss programs
            </div>
          </div>
          <ul className="divide-y divide-black/20">
            <li className="h-20 flex items-center justify-center text-sm font-medium">
              <div>$100</div>
            </li>
            <li className="h-20 flex items-center justify-center text-sm font-medium">
              <div>$200</div>
            </li>
            <li className="h-20 flex items-center justify-center text-sm font-medium">
              <div>$200</div>
            </li>
            <li className="h-20 flex items-center justify-center text-sm font-medium">
              <div>$200</div>
            </li>
            <li className="h-20 flex items-center justify-center text-sm font-medium">
              <div>$100</div>
            </li>
            <li className="h-20 flex items-center justify-center text-sm font-bold">
              <div>$700+</div>
            </li>
          </ul>
        </div>
      </div>
      <div className="flex items-center justify-center max-w-sm mx-auto mt-10">
        <ContinueButton
          enabled
          size="large"
          text="Get started for $39"
          onClick={onClick}
        />
      </div>
    </div>
  )
}

export default function Results({ currentWeight, targetWeight }: ResultsProps) {
  const { experiment } = useExperiment()
  const checkoutUrl = '/checkout'
  const [showModal, setShowModal] = useState<boolean>(true)

  const onClick = async () => {
    Router.push(checkoutUrl)
  }

  const name = useAppSelector(state => state.user.name)
  const initialized = useRef(false)

  useEffect(() => {
    trackMixpanelEvent('results-page', experiment?.funnel, {
      currentWeight,
      targetWeight,
    })

    trackGAEvent({
      eventName: 'viewed-results',
      funnelId: experiment?.funnel,
    })

    if (!checkoutUrl && !initialized.current) {
      initialized.current = true
      console.log('Firing getCheckoutLink')
    }

    // Show congrats modal
    fireConfetti({ count: 200 })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const closeModal = () => {
    setShowModal(false)
    trackMixpanelEvent('closed-results-modal', experiment?.funnel)
    trackEvent({ eventName: 'AddToCart' })
    trackGAEvent({
      eventName: 'closed-results-modal',
      funnelId: experiment?.funnel,
    })
  }

  return (
    <>
      <Modal
        showModal={showModal}
        toggleModal={closeModal}
        modalClass="modal-eligible blurred"
        closeOnOverlay={false}
        closeIconColor="text-white"
      >
        <div className="grid grid-cols-1 md:grid-cols-3">
          <div className="bg-gradient-to-b from-slate-700 to-slate-800 col-span-1 flex items-center justify-center">
            <Lottie
              animationData={checkmarkData}
              className="flex justify-center items-center max-w-[200px]"
              loop={true}
            />
          </div>
          <div className="text-center col-span-2 p-8">
            <h2 className="text-4xl md:text-3xl mb-4 italic">
              <strong>Congrats!</strong>
              {` `}
              You&apos;ve been approved! 🎉
            </h2>
            <p className="text-lg mb-4">
              We think you&apos;re a great candidate for our{' '}
              <span className="font-semibold underline decoration-gray-300">
                Prescription Weight Loss
              </span>
              {` `}
              program!<sup>*</sup>
            </p>
            <div className="my-8">
              <ContinueButton
                enabled={true}
                size="large"
                text="See my plan"
                onClick={closeModal}
              />
            </div>
            <small className="text-gray-600 text-xs mt-4 block">
              <sup>*</sup> Your clinician will have final say in the exact
              medication
            </small>
          </div>
        </div>
      </Modal>
      <div className="bg-gray-800 border-gray-400 h-20 fixed bottom-0 w-full z-10 block md:hidden">
        <div className="flex items-center justify-center h-20">
          <ContinueButton
            enabled={true}
            size="large"
            text="Get 50% off your first month"
            onClick={onClick}
          />
        </div>
      </div>
      <div className="max-w-6xl mx-auto relative">
        <div className="flex flex-col md:flex-row items-center">
          <div className="md:max-w-[50%] mb-6 md:mb-0">
            <Image
              src="/product-image.png"
              alt="Your Weight Loss Plan"
              width={800}
              height={629}
            />
          </div>
          <div>
            <h1 className="text-2xl md:text-3xl font-serif font-semibold mb-4">
              {name ? `${name}'s` : 'My'} Weight Loss Plan
            </h1>
            <div className="price-row flex gap-4 items-center text-2xl mb-8">
              <div className="old-price line-through text-gray-400">$78</div>
              <div className="discount-bubble rounded-full px-3 py-1 text-base bg-orange-600 font-semibold text-white tracking-wider">
                50% off
              </div>
              <div className="sale-price font-semibold text-orange-600">
                $39 today
              </div>
            </div>
            <h2 className="text-xl font-medium mb-4">
              Your Measured membership includes:
            </h2>
            <div className="feature-list flex flex-col gap-3">
              <div className="flex gap-1">
                <CheckCircleIcon className="w-6 h-6 relative min-w-[25px] text-slate-600" />
                <span>
                  Prescriptions to medications like <strong>Wegovy</strong>
                  <sup className="text-gray-500">®</sup> or
                  {` `}
                  <strong>Ozempic</strong>
                  <sup className="text-gray-500">®</sup>
                </span>
              </div>
              <div className="flex gap-1">
                <CheckCircleIcon className="w-6 h-6 relative min-w-[25px] text-slate-600" />
                <span>
                  <strong>Complimentary</strong> metabolic assessment lab test
                </span>
              </div>
              <div>
                <div className="flex gap-1">
                  <CheckCircleIcon className="w-6 h-6 relative min-w-[25px] text-slate-600" />
                  <span>
                    <strong>24/7 access to your Care Team</strong> including{' '}
                    <span className="italic">Obesity-trained Clinician</span>{' '}
                    and <span className="italic">Certified Health Coach</span>
                  </span>
                </div>
              </div>
              <div className="flex gap-1">
                <CheckCircleIcon className="w-6 h-6 relative min-w-[25px] text-slate-600" />
                <span>
                  Customized meal plan with{' '}
                  <strong>weekly grocery lists</strong> and recipes
                </span>
              </div>
            </div>
            <div className="items-start mt-8 sticky bottom-0 hidden md:flex">
              <ContinueButton
                enabled={true}
                size="large"
                text="Get 50% off your first month"
                onClick={onClick}
              />
            </div>
          </div>
        </div>
        <div className="my-8 px-4 py-8 md:py-20 bg-primary3 border-3 border-primary2 rounded-2xl text-center">
          <h2 className="font-semibold font-serif text-2xl mb-2">
            Your goal weight of {targetWeight} lbs is within reach!
          </h2>
          <p className="mb-8 md:text-lg">
            Based on your answers, scientific evidence suggests that you can
            expect to lose:
          </p>
          <div className="flex items-center justify-around max-w-2xl mx-auto">
            <div className="rounded-full bg-primary2 h-20 w-20 md:h-32 md:w-32 flex flex-col items-center justify-center text-lg leading-0 gap-0">
              <div className="text-xl md:text-2xl font-semibold leading-0">
                {Math.round(currentWeight * 0.04)} lbs
              </div>
              <small className="leading-0 flex">
                <span>in 3 mo</span>
                <span className="hidden md:block">nths</span>
              </small>
            </div>
            <div className="rounded-full bg-primary2 h-20 w-20 md:h-32 md:w-32 flex flex-col items-center justify-center text-lg leading-0 gap-0">
              <div className="text-xl md:text-2xl font-semibold leading-0">
                {Math.round(currentWeight * 0.07)} lbs
              </div>
              <small className="leading-0 flex">
                <span>in 3 mo</span>
                <span className="hidden md:block">nths</span>
              </small>
            </div>
            <div className="rounded-full bg-primary2 h-20 w-20 md:h-32 md:w-32 flex flex-col items-center justify-center text-lg leading-0 gap-0">
              <div className="text-xl md:text-2xl font-semibold leading-0">
                {Math.round(currentWeight * 0.14)} lbs
              </div>
              <small className="leading-0 flex">
                <span>in 6 mo</span>
                <span className="hidden md:block">nths</span>
              </small>
            </div>
          </div>
          <div className="flex items-center justify-center">
            <div className="w-full max-w-xl mx-auto mt-4">
              <WeightGraph
                currentWeight={currentWeight}
                targetWeight={targetWeight}
              />
            </div>
          </div>
          <div className="flex items-center justify-center mt-4">
            <ContinueButton
              enabled={true}
              size="large"
              text="Get 50% off your first month"
              onClick={onClick}
            />
          </div>
        </div>
        <div className="next-steps bg-gray-800 p-6 py-8 w-full rounded-2xl text-center text-gray-100">
          <h3 className="text-3xl font-serif font-semibold mb-8">Next Steps</h3>
          <div className="flex flex-col md:flex-row gap-6 justify-around">
            <div className="flex flex-col items-center">
              <div className="flex items-center justify-center text-4xl rounded-full bg-primary2 text-black w-16 h-16">
                1
              </div>
              <h4 className="text-lg md:text-xl font-semibold my-4">
                Complete Consultation
              </h4>
              <p className="md:text-lg font-light">
                Complete an online medical consultation by answering questions
                about your health and weight history.
              </p>
            </div>
            <div className="flex flex-col items-center">
              <div className="flex items-center justify-center text-4xl rounded-full bg-primary2 text-black w-16 h-16">
                2
              </div>
              <h4 className="text-lg md:text-xl font-semibold my-4">
                Get Your Treatment Plan
              </h4>
              <p className="md:text-lg font-light">
                Your clinician will recommend a treatment plan designed to
                achieve your goals. They may ask you to complete lab work before
                your 2nd refill.
              </p>
            </div>
            <div className="flex flex-col items-center">
              <div className="flex items-center justify-center text-4xl rounded-full bg-primary2 text-black w-16 h-16">
                3
              </div>
              <h4 className="text-lg md:text-xl font-semibold my-4">
                Start Losing Weight
              </h4>
              <p className="md:text-lg font-light">
                Get unlimited messaging with your Care Team for only $78/month.
                Prescription medications not included.
              </p>
            </div>
          </div>
        </div>
        <div className="my-8 p-4 md:p-8 bg-primary3 rounded-2xl">
          <h3 className="text-2xl font-serif text-center mb-8 font-medium">
            How Measured Works
          </h3>
          <div className="grid grid-rows-3 md:grid-rows-1 md:grid-cols-3 md:gap-8">
            <div className="col-span-1">
              <p>
                Measured’s $78 monthly membership gives you unlimited access to
                your Care team–a clinician, health coach, and care
                coordinator–as well as a well as a meal plan creator and an
                active community. Your membership also includes a full metabolic
                panel, which your clinician may ask you to complete before your
                prescription refill.
              </p>
            </div>
            <div className="col-span-1">
              <p className="mb-3 md:mb-4">
                Our clinicians often write prescriptions for a groundbreaking
                class of weight class drugs known as GLP-1s, which may be
                covered by your health insurance.
              </p>
              <p>
                If your clinician believes you are a fit for a GLP-1, our Prior
                Authorization Specialists will work directly with your insurance
                to get you approval and as much cost-savings as possible.
              </p>
            </div>
            <div className="col-span-1">
              <p className="mb-3 md:mb-4">
                If your insurance does not cover GLP-1s , you will have the
                option to:
              </p>
              <ul className="text-sm list-disc pl-4 space-y-3">
                <li>
                  Discuss other effective treatment options with your Measured
                  clinician, such as Metformin, Bupropion, Topiramate, and
                  Naltrexone.
                </li>
                <li>
                  Purchase compounded Semaglutide (the active ingredient in
                  Wegovy and Ozempic) for an additional fee. This will ship
                  directly to your home.
                </li>
                <li>Pay out-of-pocket for branded GLP-1 (est $1,000 /month)</li>
              </ul>
            </div>
          </div>
        </div>
        <div className="bg-primary2 rounded-2xl">
          <PriceComparisonTable onClick={onClick} enabled={true} />
        </div>
      </div>
      <div className="w-full max-w-sm mb-20">
        <BeforeAndAfter subtitle={'81% of members saw an improvement!'} />
      </div>
      <div className="">
        <GoodQuestionToAsk />
      </div>
      <div className="w-screen md:w-full md:max-w-6xl bg-brand-primary-1-default">
        <div className="max-w-lg w-full mx-auto px-4 sm:px-0 py-10">
          <ReadyToSeeResults onClick={onClick} />
        </div>
      </div>
      <div className="w-screen md:w-full md:max-w-6xl bg-secondary1/5">
        <div className="max-w-lg w-full mx-auto px-4 sm:px-0">
          <Footer />
        </div>
      </div>
    </>
  )
}
