import StepLabel from '@components/quiz/step-label'
import StepHint from '@components/quiz/step-hint'
import React, { useEffect, useMemo, useState } from 'react'
import {
  ExperimentPage,
  Gender,
  QuizProps,
  QuizStepType,
  StepChangeHandler,
  StepId,
} from '@types'
import { useAppDispatch, useAppSelector } from 'redux/hooks'
import { selectStepValue, setStepValue } from 'redux/quiz'
import { selectName, setStartTime } from 'redux/user'
import { components, getQuestionDefaultValue } from './questions'
import { useRouter } from 'next/router'
import { setQuizProgress } from 'redux/quiz/steps'
import { trackMixpanelEvent } from '@components/Scripts/MixpanelTracker'
import { getStateByZipCode } from '@utils/zip-code'
import { useExperiment } from 'redux/experiment'
import { WhyDoWeAsk } from '@components/WhyDoWeAsk'
import { trackGAEvent } from '@components/Scripts/GoogleAnalytics'
import { trackEvent } from '@utils/tracking'

const Quiz = (props: QuizProps) => {
  const { step } = props
  const currentPage = props.currentFlowPage || ExperimentPage.QUESTIONS
  const stepValue = useAppSelector(state => selectStepValue(state, step.id))
  const name = useAppSelector(selectName)
  const dispatch = useAppDispatch()
  const { asPath } = useRouter()

  const defaultValue = useMemo(() => getQuestionDefaultValue(step), [step])
  const [value, setValue] = useState(stepValue || defaultValue)
  const StepComponent = useMemo(() => components[step.type], [step.type])
  const { experiment, goNextStepFlow } = useExperiment()
  const startTime = useAppSelector(state => state.user.startTime)

  const replaceName = (value: string) =>
    name ? value.replace(/\[name\]/g, name) : value

  const isHomepage = () => {
    if (
      asPath === '/' ||
      asPath === '/quiz' ||
      asPath === '/quiz/start-female' ||
      asPath === '/quiz/start-male'
    ) {
      return true
    }
    return false
  }

  useEffect(() => {
    if (isHomepage() && !startTime) {
      dispatch(setStartTime())
      trackMixpanelEvent('quiz-start', experiment?.funnel, { url: asPath })
      trackGAEvent({
        eventName: 'quiz-start',
        funnelId: experiment?.funnel,
        page_path: asPath,
      })
      trackEvent({ eventName: 'ViewContent' })
    } else {
      trackMixpanelEvent(
        `viewed-quiz-step-${props.currentStep}-${props.step.id}`,
        experiment?.funnel,
        {
          id: props?.step?.id,
          label: props?.step?.label,
        },
      )
      trackGAEvent({
        eventName: 'quiz-step-viewed',
        funnelId: experiment?.funnel,
        stepNumber: props.currentStep,
        stepDescription: props?.step?.id,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.currentStep, asPath])

  useEffect(() => {
    setValue(stepValue || defaultValue)
    dispatch(setQuizProgress(props))
  }, [stepValue, defaultValue, setValue, dispatch, props])

  const changeHandler: StepChangeHandler<unknown> = (
    name: string,
    value: unknown,
  ) => {
    setValue(value)

    // TODO: Refactor, there's probably a much cleaner way to do this
    if (
      step.type === QuizStepType.SINGLE_CHOICE ||
      step.type === QuizStepType.LAB_RESULTS
    ) {
      dispatch(setStepValue({ stepId: step.id, value }))
      goNextStepFlow({
        currentPage,
        isLastStep: props.isLastStep,
        nextStep: props.nextStep,
      })
    }
    if (step.type === QuizStepType.ZIPCODE) {
      dispatch(setStepValue({ stepId: step.id, value }))
      dispatch(
        setStepValue({
          stepId: StepId.STATE,
          value: getStateByZipCode(value as number),
        }),
      )
      goNextStepFlow({
        currentPage,
        isLastStep: props.isLastStep,
        nextStep: props.nextStep,
      })
    }
    if (step.type === QuizStepType.INSURANCE) {
      dispatch(setStepValue({ stepId: step.id, value }))
      goNextStepFlow({
        currentPage,
        isLastStep: value !== 'commercial',
        // isLastStep: !['commercial', 'none'].includes(value as string),
        nextStep: props.nextStep,
        lastPageUrl: '/ineligible-insurance',
      })
    }
    if (step.id === StepId.GENDER && (value as Gender) === 'male') {
      dispatch(setStepValue({ stepId: StepId.PREGNANT, value: 'no' }))
    }
  }

  const submitHandler = (e: React.SyntheticEvent<Element, Event> | null) => {
    if (e) e.preventDefault()

    dispatch(setStepValue({ stepId: step.id, value }))

    trackMixpanelEvent(
      `answered-quiz-step-${props.currentStep}-${props.step.id}`,
      experiment?.funnel,
      {
        id: props?.step?.id,
        label: props?.step?.label,
        value,
      },
    )

    goNextStepFlow({
      currentPage,
      isLastStep: props.isLastStep,
      nextStep: props.nextStep,
      lastPageUrl: props.lastPageUrl,
    })
  }

  return (
    <div className={['quiz-step fade-in', step.id, step.type].join(' ')}>
      {(step.label || step.hint) &&
        step.type !== QuizStepType.CONTENT_INTRO && (
          <div className={`quiz-header ${step.hint ? 'with-hint' : ''}`}>
            {step.label && <StepLabel label={replaceName(step.label)} />}
            {step.hint && <StepHint hint={replaceName(step.hint)} />}
          </div>
        )}
      <form className="quiz-content" onSubmit={submitHandler}>
        <StepComponent
          {...props}
          key={step.id}
          value={value}
          step={step}
          onChange={changeHandler}
        />
        {step.whyDoWeAsk && (
          <WhyDoWeAsk
            label={step.whyDoWeAsk.label}
            description={step.whyDoWeAsk.description}
          />
        )}
      </form>
    </div>
  )
}

export default Quiz
