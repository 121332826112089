import { useAppSelector } from '../redux/hooks'
import { selectEmail } from '../redux/user'
import { useEffect, ReactNode, useState } from 'react'
import { useRouter } from 'next/router'
import { FacebookPixel } from '@components/Scripts/FacebookPixel'
import { trackMixpanelEvent } from '@components/Scripts/MixpanelTracker'
import DatadogRum from '@components/Scripts/DatadogRum'
import { trackGAEvent } from './Scripts/GoogleAnalytics'
import { useExperiment } from 'redux/experiment'

const scripts = {
  facebookPixel: false,
  mixpanel: false,
  // googleAnalytics: false,
  helloBar: false,
  datadogRum: false,
}

export default function Tracking({ children }: { children: ReactNode }) {
  const router = useRouter()
  const email = useAppSelector(selectEmail)
  const [loaded, setLoaded] = useState(scripts)
  const { experiment } = useExperiment()

  const setLoadedScript = (name: string) => {
    console.log('loaded', name)
    setLoaded({ ...loaded, [name]: true })
  }
  // Track client-side page views.
  const handleRouteChange = (url: string) => {
    trackGAEvent({
      eventName: 'page_view',
      funnelId: experiment?.funnel,
      page_path: url,
    })

    if (process.env.NEXT_PUBLIC_MIXPANEL_API_KEY) {
      trackMixpanelEvent('page-view', experiment?.funnel, { url })
    }
  }

  useEffect(() => {
    router.events.on('routeChangeComplete', handleRouteChange)
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [email, router.events, router.asPath])

  return (
    <>
      {process.env.NEXT_PUBLIC_FACEBOOK_PIXEL_ID && !loaded?.facebookPixel && (
        <FacebookPixel setLoadedScript={setLoadedScript} />
      )}
      {process.env.NEXT_PUBLIC_DATADOG_RUM_TOKEN && !loaded?.datadogRum && (
        <DatadogRum setLoadedScript={setLoadedScript} />
      )}
      {children}
    </>
  )
}
