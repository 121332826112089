import { Experiment } from '@types'
import survey from './survey'
import r1 from './r1'
import exp1 from './exp1'
import glp1 from './glp1'
import exp2 from './exp2'

const experiments: Experiment[] = [survey, r1, exp1, glp1, exp2]

export default experiments
