/* eslint-disable */
export const trackMixpanelEvent = (
  event_name: string,
  funnel?: string,
  props?: Record<any, any>,
) => {
  try {
    if ((window as any).mixpanel) {
      ;(window as any).mixpanel.track(event_name, {
        ...props,
        funnel: funnel || '8am-pt1',
      })
      console.log('Mixpanel Event', event_name, funnel, props)
    }
  } catch (e) {
    console.log(e)
  }
}
