import { Experiment } from '@types'
import survey from '../survey'

const exp1: Experiment = {
  ...survey,
  id: 'exp1',
  funnel: 'EXP1',
  default: false,
}

export default exp1
