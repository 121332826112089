import { createSlice } from '@reduxjs/toolkit'
import { EligiblityReason, Track } from '@types'
import { selectAnswers } from '../quiz'
import { RootState } from '../store'
import { conditionsQuestion } from '@data/questions/base'

// Define a type for the slice state
export type EligiblityState = {
  age: {
    max: number
    min: number
  }
  condition: {
    exclude: Array<string>
  }
  healthCondition: {
    exclude: Array<string>
  }
  track: {
    exclude: Array<Track>
  }
  insuranceProvider: {
    exclude: Array<string>
  }
  pregnant: {
    exclude: Array<string>
  }
}

// Define the initial state using that type
const initialState: EligiblityState = {
  age: {
    max: 100,
    min: 21,
  },
  condition: {
    exclude: conditionsQuestion
      .options!.filter(c => c.value !== 'none')
      .map(o => o.value.toString()),
  },
  healthCondition: {
    exclude: ['heart_disease'],
  },
  track: { exclude: [Track.BEHAVIORAL] },
  insuranceProvider: {
    exclude: ['commercial', 'none'],
  },
  pregnant: {
    exclude: ['yes'],
  },
}

export const eligiblitySlice = createSlice({
  name: 'eligiblity',
  initialState,
  reducers: {
    resetEligiblity: state => {
      console.log({
        'initialState.condition': initialState.condition,
      })
      state.age = initialState.age
      state.condition = initialState.condition
      state.healthCondition = initialState.healthCondition
      state.track = initialState.track
      state.insuranceProvider = initialState.insuranceProvider
    },
  },
})

const isEligibleAge = (state: RootState): boolean => {
  const age = selectAnswers(state).age
  if (typeof age === 'number') {
    const { age: ageLimits } = state.eligiblity as EligiblityState
    return age >= ageLimits.min && age <= ageLimits.max
  }
  return true
}

const isEligibleTrack = (state: RootState): boolean => {
  const { track: trackLimits } = state.eligiblity as EligiblityState
  const limits = trackLimits || initialState.track
  return !limits?.exclude?.includes(state.user.track)
}

function intersect<T>(a: Array<T>, b: Array<T>): Array<T> {
  const setA = new Set<T>(a)
  const setB = new Set<T>(b)
  const intersection = new Set([...setA].filter(x => setB.has(x)))
  return Array.from(intersection)
}

const isEligibleCondition = (state: RootState): boolean => {
  const conditions = selectAnswers(state).conditions
  if (conditions && conditions.length > 0) {
    const { condition: conditionRestrictions } =
      state.eligiblity as EligiblityState

    return intersect(conditions, conditionRestrictions.exclude).length === 0
  }
  return true
}

const isEligiblePregnant = (state: RootState): boolean => {
  const userPregnantAnswer = selectAnswers(state).pregnant

  // Check if the user's answer is in the 'exclude' array
  if (initialState.pregnant.exclude.includes(userPregnantAnswer)) {
    return false // Not eligible if the answer is in the 'exclude' list
  }

  return true
}

const isEligibleHealthCondition = (state: RootState): boolean => {
  const conditions = selectAnswers(state).health_conditions
  if (conditions && conditions.length > 0) {
    const { healthCondition: conditionRestrictions } =
      state.eligiblity as EligiblityState
    return intersect(conditions, conditionRestrictions.exclude).length === 0
  }
  return true
}

export const selectIsEligible = (state: RootState): boolean => {
  return (
    isEligibleAge(state) &&
    isEligibleCondition(state) &&
    isEligibleHealthCondition(state) &&
    isEligibleTrack(state) &&
    isEligiblePregnant(state)
  )
}

export const selectIneligiblityReason = (
  state: RootState,
): ({ eligiblity: EligiblityReason } & EligiblityState) | null => {
  if (!isEligibleAge(state)) {
    return {
      eligiblity: EligiblityReason.AGE,
      ...state.eligiblity,
    }
  }
  if (!isEligibleCondition(state) || !isEligibleHealthCondition(state)) {
    return {
      eligiblity: EligiblityReason.CONDITION,
      ...state.eligiblity,
    }
  }
  if (!isEligibleTrack(state)) {
    return {
      eligiblity: EligiblityReason.TRACK,
      ...state.eligiblity,
    }
  }
  if (!isEligibleTrack(state)) {
    return {
      eligiblity: EligiblityReason.TRACK,
      ...state.eligiblity,
    }
  }
  if (!isEligiblePregnant(state)) {
    return {
      eligiblity: EligiblityReason.PREGNANT,
      ...state.eligiblity,
    }
  }
  return null
}

export const { resetEligiblity } = eligiblitySlice.actions

export default eligiblitySlice.reducer
